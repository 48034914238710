import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useCtaRegistration } from '~hooks/useAuth';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import MessageBox from '~components/forms/MessageBox';
import Container from '~components/Container';
import { lineFix } from '~utils/popup-helpers';

const useStyles = makeStyles({
  inputMargin: {
    marginTop: '4px'
  },
  buttonBackground: {
    backgroundColor: props => props.button_background_color,
    color: props => props.button_text_color
  }
});

const EmailAcquisitionLine = memo(
  ({
    cta_text,
    one_line_email_background_color,
    cta_text_color,
    button_title,
    button_background_color,
    button_text_color,
    style
  }) => {
    const classes = useStyles({ button_background_color, button_text_color });

    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
    const { session } = useUserContext();
    const { isUserStateLoading, jwtAvailable } = session;

    const { register, handleSubmit } = useForm();
    const [regState, registerCta] = useCtaRegistration();
    const { isLoading, isError, error } = regState;

    const onSubmit = ({ username }) => {
      // NOTE: MUST be one of the backend/src/models/RegistrationStrategy.ts
      registerCta({ username, strategy: 'one-line-sign-up' });
    };

    if (jwtAvailable || isUserStateLoading) {
      return null;
    }

    return (
      <Container
        background={one_line_email_background_color || '#f9f0e7'}
        style={{ marginTop: '40px', ...style }}
      >
        <Box px={4} py={isSmallerThanMd ? 2 : 0} width="100%">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isError && <MessageBox text={error} isError />}
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  component="h2"
                  style={{ fontWeight: '600', color: cta_text_color }}
                >
                  {lineFix(cta_text)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput
                  label="Enter Your Email Address"
                  id="username-signup-form"
                  name="username"
                  type="email"
                  inputRef={register}
                  required
                  margin="dense"
                  disabled={isLoading}
                  className={classes.inputMargin}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ButtonSubmit className={classes.buttonBackground}>{button_title}</ButtonSubmit>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    );
  }
);

EmailAcquisitionLine.propTypes = {
  cta_text: PropTypes.string.isRequired,
  one_line_email_background_color: PropTypes.string,
  cta_text_color: PropTypes.string,
  button_title: PropTypes.string.isRequired,
  button_background_color: PropTypes.string,
  button_text_color: PropTypes.string,
  style: PropTypes.shape({})
};

EmailAcquisitionLine.defaultProps = {
  one_line_email_background_color: null,
  cta_text_color: '#000',
  button_background_color: '#2c7abf',
  button_text_color: '#fff',
  style: {}
};

export default EmailAcquisitionLine;
